import Vue from 'vue';
import { Link } from '../../types/link';
import { FlyoutModel } from '../Flyout/Flyout.props';
import { LinkListModel } from '../LinkList/LinkList.props';
import { setSafeTimeout } from '../../assets/js/utilities/timeout';
import { HeaderDesktopProps } from './HeaderDesktop.props';

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'HeaderDesktop',
	props: HeaderDesktopProps,
	data() {
		return {
			flyoutIndexChanged: false,
			flyoutIndex: null as number | null,
			showSearch: false,
		};
	},
	computed: {
		linkList(): LinkListModel {
			return {
				uid: 'HEADER_CATEGORIES',
				links: this.categories,
				activeLinkIndex: this.flyoutIndex as number,
				flyout: true,
			};
		},
		flyout(): FlyoutModel {
			return {
				uid: 'HEADER_FLYOUT',
				data: this.flyouts[this.flyoutIndex as number],
				headerHeight: this.headerHeight,
			};
		},
		showFlyout() {
			return this.flyoutIndex !== null && this.flyouts[this.flyoutIndex];
		},
	},
	watch: {
		flyoutIndex() {
			this.flyoutIndexChanged = true;
			setTimeout(() => {
				this.flyoutIndexChanged = false;
			}, 100);
		},
	},
	methods: {
		handleLanguageChange(language: Link) {
			this.$emit('language-change', language);
		},
		handleFlyoutEnter(data: { index: number; text: string; $event: MouseEvent }) {
			if (this.BREAKPOINT.IS_DESKTOP && data.$event.relatedTarget) {
				const flyout = this.flyouts[data.index];
				const flyoutLinkText = flyout?.flyoutData.parentCategory.text;

				this.flyoutIndex = flyoutLinkText === data.text || !flyout ? data.index : null;
			}
		},
		handleFlyoutLeave() {
			// Necessary to prevent unexpected redirect after clicking overlay
			setSafeTimeout(() => {
				this.flyoutIndex = null;
			});
		},
		handleLinkClick(data: { index: number; text: string }): void {
			const flyout = this.flyouts[data.index];

			if (data.index !== this.flyoutIndex && flyout) {
				const flyoutLinkText = flyout?.flyoutData.parentCategory.text;

				this.flyoutIndex = flyoutLinkText === data.text ? data.index : null;

				return;
			}

			this.$router.push(this.linkList.links[data.index].href);
			this.handleFlyoutLeave();
		},
		handleSearchTrigger() {
			this.$nextTick(() => {
				this.showSearch = !this.showSearch;
			});
		},
	},
});
