import { render, staticRenderFns } from "./Logo.vue?vue&type=template&id=61896b94&"
import script from "./Logo.ts?vue&type=script&lang=ts&"
export * from "./Logo.ts?vue&type=script&lang=ts&"
import style0 from "./Logo.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../shop/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InlineSvg: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/InlineSvg/InlineSvg.vue').default})
