import Vue from 'vue';
import { InlineSvgModel } from '../InlineSvg/InlineSvg.props';
import { LogoProps } from './Logo.props';

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'Logo',
	props: LogoProps,
	computed: {
		inlineSvgData(): InlineSvgModel {
			return { uid: 'LOGO_INLINESVG', name: this.name };
		},
	},
});
