import { PropType } from 'vue';
import { Props } from '../../types/core';

// Model -------------------------------------------------------------------------------------------

export interface MiniCartModel {
	uid: string;
	href: string;
	amount?: number;
}

// Props -------------------------------------------------------------------------------------------

export const MiniCartProps: Props<MiniCartModel> = {
	uid: {
		type: String as PropType<MiniCartModel['uid']>,
		required: true,
	},
	href: {
		type: String as PropType<MiniCartModel['href']>,
		required: true,
	},
	amount: {
		type: Number as PropType<MiniCartModel['amount']>,
	},
};
