import { PropType } from 'vue';
import { Props } from '../../types/core';
import { Link } from '../../types/link';

// Model -------------------------------------------------------------------------------------------

export interface LinkListModel {
	uid: string;
	flyout?: boolean;
	links: Link[];
	activeLinkIndex?: number;
}

// Props -------------------------------------------------------------------------------------------

export const LinkListProps: Props<LinkListModel> = {
	uid: {
		type: String as PropType<LinkListModel['uid']>,
		required: true,
	},
	flyout: {
		type: Boolean as PropType<LinkListModel['flyout']>,
		default: false,
	},
	links: {
		type: Array as PropType<LinkListModel['links']>,
		required: true,
	},
	activeLinkIndex: {
		type: Number as PropType<LinkListModel['activeLinkIndex']>,
	},
};
