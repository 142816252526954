import { PropType } from 'vue';
import { Props } from '../../types/core';

// Model -------------------------------------------------------------------------------------------

export interface LogoModel {
	uid: string;
	title: string;
	link: string;
	name: string;
	logoClass?: string;
	logoWrapperClass?: string;
}

// Props -------------------------------------------------------------------------------------------

export const LogoProps: Props<LogoModel> = {
	uid: {
		type: String as PropType<LogoModel['uid']>,
		required: true,
	},
	title: {
		type: String as PropType<LogoModel['title']>,
		required: true,
	},
	link: {
		type: String as PropType<LogoModel['link']>,
		required: true,
	},
	name: {
		type: String as PropType<LogoModel['name']>,
		required: true,
	},
	logoClass: {
		type: String as PropType<LogoModel['logoClass']>,
		default: undefined,
	},
	logoWrapperClass: {
		type: String as PropType<LogoModel['logoWrapperClass']>,
		default: undefined,
	},
};
