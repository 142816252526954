import { PropType } from 'vue';
import { Props } from '../../types/core';
import { HeaderFlyout } from '../../types/header';
import { Link } from '../../types/link';
import { LinkListModel } from '../LinkList/LinkList.props';
import { LogoModel } from '../Logo/Logo.props';
import { MiniCartModel } from '../MiniCart/MiniCart.props';
import { SearchModel } from '../Search/Search.props';
import { SearchTriggerModel } from '../SearchTrigger/SearchTrigger.props';
import { UtilityNavModel } from '../UtilityNav/UtilityNav.props';

// Model -------------------------------------------------------------------------------------------

export interface HeaderDesktopModel {
	uid: string;
	genderNavigation: LinkListModel;
	logo: LogoModel;
	utility: UtilityNavModel;
	categories: Link[];
	flyouts: HeaderFlyout[];
	cart: MiniCartModel;
	headerHeight: number;
	search: SearchModel;
	searchTrigger: SearchTriggerModel;
}

// Props -------------------------------------------------------------------------------------------

export const HeaderDesktopProps: Props<HeaderDesktopModel> = {
	uid: {
		type: String as PropType<HeaderDesktopModel['uid']>,
		required: true,
	},
	genderNavigation: {
		type: Object as PropType<HeaderDesktopModel['genderNavigation']>,
		required: true,
	},
	logo: {
		type: Object as PropType<HeaderDesktopModel['logo']>,
		required: true,
	},
	utility: {
		type: Object as PropType<HeaderDesktopModel['utility']>,
		required: true,
	},
	categories: {
		type: Array as PropType<HeaderDesktopModel['categories']>,
		required: true,
	},
	flyouts: {
		type: Array as PropType<HeaderDesktopModel['flyouts']>,
		required: true,
	},
	cart: {
		type: Object as PropType<HeaderDesktopModel['cart']>,
		required: true,
	},
	headerHeight: {
		type: Number as PropType<HeaderDesktopModel['headerHeight']>,
		required: true,
	},
	search: {
		type: Object as PropType<HeaderDesktopModel['search']>,
		required: true,
	},
	searchTrigger: {
		type: Object as PropType<HeaderDesktopModel['searchTrigger']>,
		required: true,
	},
};
