import Vue from 'vue';
import { SearchTriggerProps } from './SearchTrigger.props';

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'SearchTrigger',
	props: SearchTriggerProps,
	methods: {
		placeholder(): string {
			return this.label || (this.$t(this.$i18nKeys.searchTrigger.lookingFor) as string);
		},
	},
});
