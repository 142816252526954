import { PropType } from 'vue';
import { Props } from '../../types/core';

// Model -------------------------------------------------------------------------------------------

export interface SearchTriggerModel {
	uid: string;
	label?: string;
}

// Props -------------------------------------------------------------------------------------------

export const SearchTriggerProps: Props<SearchTriggerModel> = {
	uid: {
		type: String as PropType<SearchTriggerModel['uid']>,
		required: true,
	},
	label: {
		type: String as PropType<SearchTriggerModel['label']>,
		default: '',
	},
};
