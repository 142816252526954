import { render, staticRenderFns } from "./HeaderDesktop.vue?vue&type=template&id=b3d7d3f4&"
import script from "./HeaderDesktop.ts?vue&type=script&lang=ts&"
export * from "./HeaderDesktop.ts?vue&type=script&lang=ts&"
import style0 from "./HeaderDesktop.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../shop/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LinkList: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/LinkList/LinkList.vue').default,Logo: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/Logo/Logo.vue').default,UtilityNav: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/UtilityNav/UtilityNav.vue').default,SearchTrigger: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/SearchTrigger/SearchTrigger.vue').default,MiniCart: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/MiniCart/MiniCart.vue').default})
